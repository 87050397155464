import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout"
import NewEventsPageSection from "../components/sections/NewEventsPageSection"

const paragraph = 'font-body text-lg text-gray-900 mb-4'

const Events = () => (
  <MainContainer>
    <HeadData
      title='Events'
      description='List of Orange County Sigma Chi Alumni Chapter events.'
    />

    <OneColumnPageLayout>
      <h2 className='font-display text-4xl text-blue-900 mb-8 leading-tight'>List of Events</h2>

      <p className={paragraph}>Orange County Sigma Chi Alumni Chapter Events Calendar:</p>

      <NewEventsPageSection />
    </OneColumnPageLayout>
  </MainContainer>
)

export default Events
