import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Date } from 'prismic-reactjs'

const NewEventsPageSection = () => {
  const data = useStaticQuery(graphql`
    query NewEventsPageSectionQuery {
      prismicNewEventsPage {
        data {
          page_title
          events {
            event_title
            event_date
            event_link {
              url
            }
          }
        }
      }
    }
  `)

  const doc = data.prismicNewEventsPage

  const events = doc.data.events

  return (
    <>
      {events.map(event => {
        const date = Date(event.event_date)
        const formattedDate = Intl.DateTimeFormat('en-US',{
          year: 'numeric',
          month: 'short',
          day: '2-digit' }).format(date)
        // Outputs as something like "Mar 11, 2020"

        return (
          <div className='border rounded mt-8 p-6'>
            <p className='font-body text-lg text-gray-800 mb-6'>{formattedDate}</p>
            <h3 className='font-display text-2xl font-bold text-gray-900 leading-tight'>{event.event_title}</h3>

            <a href={event.event_link.url} target='_blank' rel='noopener noreferrer'>
              <button className="bg-blue-800 hover:bg-blue-900 text-white text-lg font-bold py-3 px-6 mt-6 rounded">
                Register For Event
              </button>
            </a>
          </div>
        )
      })}
    </>
  )
}

export default NewEventsPageSection