import React from 'react'
import PropTypes from 'prop-types'

const OneColumnPageLayout = ({ children }) => (
  <div className='sm:w-full sm:p-4 sm:py-12 md:w-10/12 lg:w-6/12 xl:w-4/12 pt-12 pb-16 mx-auto'>
    {children}
  </div>
)

OneColumnPageLayout.propTypes = {
  children: PropTypes.node,
}

export default OneColumnPageLayout